export const ApiName = {
  generateToken: "authenticate",
  getFlow: "getFlow",
  awarenessFlow:"awarenessFlow",
  subscribe: "subscribe",
  ffSubscribe: "ffSubscribe",
  utmList:"utmList",
  sendOtp:"sendOtp",
  checkOperator:"checkOperator"
};
