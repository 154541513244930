import React, { useState, useEffect } from "react";
import "../styles/Landing.css";
import successIcon from "../Assets/imgs/successIcon.png";
import mainImg from "../Assets/imgs/main-img.webp";
type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  onResendOtpPressed: () => void;
  inputRef: React.RefObject<HTMLInputElement>[];

  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  phoneNo: string;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  seconds: number;
  validNum: boolean;
  isHidden: boolean;
  handleOverLay: () => void;
  package_id: string | null;
  isHidden2: boolean;
  handleOverLay2: () => void;
};
export const Landing = (props: Props) => {
  const { one, two, three, four } = props.otp;

  // privacy modal
  const [isShow, setIsPrivacyModalOpen] = useState(false);
  const privacyModal = () => {
    setIsPrivacyModalOpen(!isShow);
  };
  // privacy modal

  return (
    // <div className="lm-landingpage">
    <div className="">
      {/* privacy modal */}
      {isShow && (
        <>
          <div className="modal-overlay" onClick={privacyModal}></div>

          <div className="pr-modal">
            <div className="modalHead">
              <div className="modal-heading">
                Features & <span>Benefits:</span>
              </div>
              <button className="pr-close-modal" onClick={privacyModal}>
                X
              </button>
            </div>

            <div className="pr-modal-content">
              <ul>
                <li>
                  {" "}
                  24/7 unlimited teleconsultations with qualified doctors via a
                  dedicated helpline 042 111 11 9878.
                </li>
                <li>
                  {" "}
                  Appointment-based access to specialist doctors, including
                  Expert Nutritionists, Gynecologists, Pediatricians, and Mental
                  Health Expert.
                </li>
                <li>
                  {" "}
                  Hospitalization Cover – up to 30 nights covered in a year.
                </li>
                <li>
                  {" "}
                  Swift & Hassle-free insurance claim settlement though Online
                  payment channels within 72 working hours.
                </li>
                <li>
                  {" "}
                  Doorstep delivery of discounted medicines (Up to 10%
                  discount).
                </li>
                <li>
                  {" "}
                  Doorstep home sampling with Discounted Lab tests (Up to 40%).
                </li>
                <li> Personalized diet & workout plans.</li>
                <li> Regular health tips to improve your daily life.</li>
                <li> Coaching from mental health expert.</li>
                <li> Access to family health.</li>
              </ul>
            </div>
          </div>
        </>
      )}
      {/* privacy modal */}

      {/* <button onClick={toggleModal}>Open Modal</button> */}
      {props.isHidden === false && (
        <>
          <div
            className="modal-overlay"
            onClick={() => props.handleOverLay()}
          ></div>

          <div className="modal">
            <button
              className="close-modal"
              onClick={() => props.handleOverLay()}
            >
              X
            </button>
            <div className="modal-content">
              <img src={successIcon} alt="icon" />
              <p>You are successfully subscribed to Bima Pakistan.</p>
              <p> Please call (042) 111 119 878 for more details.</p>
            </div>
          </div>
        </>
      )}
      {props.isHidden2 === false && (
        <>
          <div
            className="modal-overlay"
            onClick={() => props.handleOverLay2()}
          ></div>

          <div className="modal">
            <button
              className="close-modal"
              onClick={() => props.handleOverLay2()}
            >
              X
            </button>
            <div className="modal-content">
              <img src={successIcon} alt="icon" />
              <p>You're Already Subscribed to Bima Pakistan.</p>
              <p> Please call (042) 111 119 878 for more details.</p>
            </div>
          </div>
        </>
      )}
      <div className="dm-section">
        <div className="main-content">
          <img
            src={mainImg}
            alt="sports"
            width={345}
            height={333}
            className="lp-main-image"
          />
          <div className="lp-absolute-content">
            <div className="numberdiv">
              <p className="landingtext">Please enter your phone number</p>

              <div className="input-wraper">
                <span>+92</span>
                <input
                  className="numberinput"
                  type="number"
                  placeholder="3XX - XXXXXXX"
                  max={10}
                  value={props.phoneNo}
                  onChange={(e) => {
                    props.handlePhoneNoChange(e);
                  }}
                />
              </div>
            </div>
            {/* otp div */}
            {props.showError && <p className="error">{props.errorMsg}</p>}
            {/* <p className="error">error message</p> */}

            <div className="otpDiv">
              {props.phoneNo.length === 10 && props.validNum ? (
                <>
                  <p className="landingtext">
                    Please enter the OTP received via SMS.
                  </p>
                  <div className="otpinputWraper">
                    <input
                      type="tel"
                      name="one"
                      maxLength={1}
                      autoFocus
                      value={props.otp.one}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 0);
                      }}
                      ref={props.inputRef[0]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 0);
                      }}
                    />
                    <input
                      type="tel"
                      name="two"
                      maxLength={1}
                      value={props.otp.two}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 1);
                      }}
                      ref={props.inputRef[1]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 1);
                      }}
                    />
                    <input
                      type="tel"
                      name="three"
                      maxLength={1}
                      value={props.otp.three}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 2);
                      }}
                      ref={props.inputRef[2]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 2);
                      }}
                    />
                    <input
                      type="tel"
                      name="four"
                      maxLength={1}
                      value={props.otp.four}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 3);
                      }}
                      ref={props.inputRef[3]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 3);
                      }}
                    />
                  </div>

                  {/* <div className="invalidotp">Invalid OTP entered</div> */}
                  <div className="otpcounter">
                    <p>
                      {props.seconds === 0 ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={props.onResendOtpPressed}
                        >
                          Resend Otp
                        </a>
                      ) : null}
                      {props.seconds > 0 && (
                        <>
                          <>Resend 00:{props.seconds}</>
                          {props.seconds === 0 && (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={props.onResendOtpPressed}
                            >
                              Resend Otp
                            </a>
                          )}
                        </>
                      )}
                    </p>
                  </div>

                  {props.showLoader ? (
                    <div className="subscribe-btn">
                      <button>Loading...</button>
                    </div>
                  ) : (
                    <>
                      {one && two && three && four ? (
                        <div className="subscribe-btn">
                          <button onClick={props.onSubscribePressed}>
                            Subscribe Now
                          </button>
                        </div>
                      ) : (
                        <div className="subscribe-btn">
                          <button>Subscribe Now</button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : null}

              <div className="policttext">
                {props.package_id === "1" ? (
                  <p>Price Point : 3 PKR per day</p>
                ) : (
                  <>
                    {props.package_id === "2" ? (
                      <p>Price Point : 5 PKR per day</p>
                    ) : (
                      <>
                        {props.package_id === "3" ? (
                          <p>Price Point : 9.5 PKR per day</p>
                        ) : (
                          <>
                            {props.package_id === "4" ? (
                              <p>Price Point : 17 PKR per day</p>
                            ) : (
                              <p>Price Point : 1.2 PKR per day</p>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="terms-wraper">
                <a
                  href="https://customerportal.milvikpakistan.com/bimasehat/terms-and-conditions"
                  target="_blank"
                  className="privacypolicy"
                >
                  Terms & Conditions
                </a>
                <span className="dot"></span>
                <a href="#" onClick={privacyModal} className="privacypolicy">
                  Features & Benefits
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Landing;
