import TagManager from "react-gtm-module";
const tagManagerEvents = (eventName:any, utm_source:string|null) => {
  // console.warn("this is event name", eventName, "utm source", utm_source);
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      utm_source: utm_source,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
const landingTagMangerEvent = (eventName:any, utm_source:string) => {};
export default tagManagerEvents;