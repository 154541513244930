import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AwarenessScreen from "./Screens/AwarenessScreen";
import LandingScreen from "./Screens/LandingScreen";
import TagManager from "react-gtm-module";
import PrivacyPolicy from "./components/PrivacyPolich";
import { generateTokenService } from "./Services/Subscription";
import { IgenTokenApiResp } from "./@types/ApiResponse";

function App() {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId:"GTM-54T88C3N",
    };
    TagManager.initialize(tagManagerArgs);
    handleGenerateToken();
  }, []);

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let response: IgenTokenApiResp= await generateTokenService();
      if (response.status === 0) {
        setToken(response.response.token);
      }else{
        console.log("Something went wrong")
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };
  return (
    <>
      
        <Routes>
          <Route path="/*" element={<Navigate to="/landing" />} />
          <Route path="/landing" element={<LandingScreen token={token}/>} />
          <Route path="/awareness" element={<AwarenessScreen token={token}/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    </>
  );
}

export default App;

